import { cn } from "~/lib/utils";

export default function Container(props: {
  children: React.ReactNode;
  className?: string;
  size?: "lg" | "md" | "sm" | "xs";
}) {
  const size = props.size ?? "md";
  return (
    <div
      className={cn(
        "mx-auto p-4",
        size === "xs" && "max-w-2xl",
        size === "sm" && "max-w-4xl",
        size === "md" && "max-w-7xl",
        size === "lg" && "max-w-[1440px]",
        props.className,
      )}
    >
      {props.children}
    </div>
  );
}
